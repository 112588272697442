import { toAbsoluteUrl } from "../../../src/helpers/AssetHelpers"; // Adjust the import path as needed.

const breakpoints = [1280, 640, 384, 256, 128, 96, 64, 48];

const generatePhotos = (images: any[]) => {
    const photos = images.map((photo) => ({
        src: toAbsoluteUrl(photo.image),
        width: photo.width,
        height: photo.height,
        description: photo.description,
        srcSet: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: `${toAbsoluteUrl(photo.image)} ${breakpoint}w`, // Adjust the path accordingly
                width: breakpoint,
                height,
                description: photo.description
            };
        }),
    }));

    return photos;
};

export default generatePhotos;
